<template>
    <h1 class="headline -h1" v-if="headlineType === 'h1'"><slot></slot></h1>
    <h2 class="headline -h2" v-else-if="headlineType === 'h2'"><slot></slot></h2>
    <h3 class="headline -h3" v-else-if="headlineType === 'h3'"><slot></slot></h3>
    <h4 class="headline -h4" v-else-if="headlineType === 'h4'"><slot></slot></h4>
    <h5 class="headline -h5" v-else-if="headlineType === 'h5'"><slot></slot></h5>
    <h6 class="headline -h6" v-else-if="headlineType === 'h6'"><slot></slot></h6>
</template>


<script>
export default {
    name: 'headline',
    props: {
        headlineType: String,
    },
};
</script>

<style src="./_headline.scss" lang="scss" scoped></style>
