import { render, staticRenderFns } from "./contentWrapper.vue?vue&type=template&id=71e3fa52&scoped=true"
import script from "./contentWrapper.vue?vue&type=script&lang=js"
export * from "./contentWrapper.vue?vue&type=script&lang=js"
import style0 from "./_contentWrapper.scss?vue&type=style&index=0&id=71e3fa52&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e3fa52",
  null
  
)

export default component.exports