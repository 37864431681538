var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footerMain"},[_c('div',{staticClass:"footerMain__content"},[_c('div',{staticClass:"footerMain__box01"},[_vm._m(0),_c('div',{staticClass:"footerMain__contact"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('buttonSmall',{class:'-isLight',attrs:{"href":'/weber.vcf',"target":'_blank'}},[_vm._v("vCard herunterladen")])],1),_vm._m(4),_vm._m(5)]),_vm._m(6)]),_c('cookieBanner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerMain__logo"},[_c('div',{staticClass:"footerMain__logoLeft"},[_vm._v(" Weber ")]),_c('div',{staticClass:"footerMain__logoRight"},[_vm._v(" Metallbearbeitungs GmbH & Co. KG ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Schmalenhoferstraße 59"),_c('br'),_vm._v("42551 Velbert"),_c('br'),_vm._v("Deutschland")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"footerMain__contactLabel"},[_vm._v("Tel:")]),_c('a',{attrs:{"href":"tel:+492051253255"}},[_vm._v("+49 (0) 2051 - 25 32 55")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"footerMain__contactLabel"},[_vm._v("Mail:")]),_c('a',{attrs:{"href":"mailto:info@weber-velbert.de"}},[_vm._v("info@weber-velbert.de")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerMain__partner"},[_c('a',{staticClass:"footerMain__partnerLogo",attrs:{"href":"/ueber-uns.html","title":"Zertifizierung"}},[_c('img',{attrs:{"src":require("@/assets/images/logo_szi.svg"),"width":"147","height":"121","alt":"SZI Qualitätsmanagement nach ISO 9001:2015 Logo"}})]),_c('a',{staticClass:"footerMain__partnerLogo",attrs:{"href":"https://schluesselregion.de","target":"_blank","title":"Die Schlüsselregion Velbert Heiligenhaus"}},[_c('img',{attrs:{"src":require("@/assets/images/schluesselregion.svg"),"width":"279","height":"100","alt":"Schlüsselregion Velbert Heiligenhaus Logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerMain__metaNavigation"},[_c('ul',{staticClass:"footerMain__metaNavigationList"},[_c('li',{staticClass:"footerMain__metaNavigationItem"},[_c('a',{attrs:{"href":"/agb.html"}},[_vm._v("AGB")])]),_c('li',{staticClass:"footerMain__metaNavigationItem"},[_c('a',{attrs:{"href":"/datenschutz.html"}},[_vm._v("Datenschutz")])]),_c('li',{staticClass:"footerMain__metaNavigationItem"},[_c('a',{attrs:{"href":"/impressum.html"}},[_vm._v("Impressum")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerMain__box02"},[_c('div',{staticClass:"footerMain__navigation"},[_c('ul',{staticClass:"footerMain__navigationList"},[_c('li',{staticClass:"footerMain__navigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung.html"}},[_vm._v("Metallbearbeitung in Velbert")]),_c('ul',{staticClass:"footerMain__subNavigationList"},[_c('li',{staticClass:"footerMain__subNavigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung/gleitschleifen.html"}},[_vm._v("Gleitschleifen")])]),_c('li',{staticClass:"footerMain__subNavigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung/kugelpolieren.html"}},[_vm._v("Kugelpolieren")])]),_c('li',{staticClass:"footerMain__subNavigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung/chemo-finish-polieren.html"}},[_vm._v("Chemo-Finish Polieren")])]),_c('li',{staticClass:"footerMain__subNavigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung/strahltechnik/magnesiumstrahlen.html"}},[_vm._v("Magnesiumstrahlen")])]),_c('li',{staticClass:"footerMain__subNavigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung/strahltechnik/haengebahnstrahlen.html"}},[_vm._v("Hängebahnstrahlen")])]),_c('li',{staticClass:"footerMain__subNavigationItem"},[_c('a',{attrs:{"href":"/metallbearbeitung/strahltechnik/muldenbandstrahlen.html"}},[_vm._v("Muldenbandstrahlen")])])])]),_c('li',{staticClass:"footerMain__navigationItem"},[_c('a',{attrs:{"href":"/ueber-uns.html"}},[_vm._v("Über uns")])]),_c('li',{staticClass:"footerMain__navigationItem"},[_c('a',{attrs:{"href":"/unternehmenspolitik.html"}},[_vm._v("Unternehmenspolitik")])]),_c('li',{staticClass:"footerMain__navigationItem"},[_c('a',{attrs:{"href":"/service.html"}},[_vm._v("Service")])]),_c('li',{staticClass:"footerMain__navigationItem"},[_c('a',{attrs:{"href":"/kontakt.html"}},[_vm._v("Kontakt")])])])])])
}]

export { render, staticRenderFns }