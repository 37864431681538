import { render, staticRenderFns } from "./buttonStd.vue?vue&type=template&id=54b33def&scoped=true"
import script from "./buttonStd.vue?vue&type=script&lang=js"
export * from "./buttonStd.vue?vue&type=script&lang=js"
import style0 from "./_buttonStd.scss?vue&type=style&index=0&id=54b33def&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b33def",
  null
  
)

export default component.exports