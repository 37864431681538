<template>
    <a class="linkTileStd">
        <div class="linkTileStd__content">
            <slot></slot>
            <span class="linkTileStd__icon">
                <font-awesome-icon icon="long-arrow-alt-right" />
            </span>
        </div>
    </a>
</template>


<script>
import AOS from 'aos';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLongArrowAltRight);

export default {
    name: 'linkTileStd',
    props: {
        headlineType: String,
    },
    components: {
        FontAwesomeIcon,
    },
    created: () => {
        AOS.init();
    },
};
</script>

<style src="./_linkTileStd.scss" lang="scss" scoped></style>
