<template>
    <a class="buttonStd" v-if="isButton === false">
        <span class="buttonStd__text">
            <slot>Button</slot>
        </span>
        <span class="buttonStd__icon">
            <font-awesome-icon icon="long-arrow-alt-right" />
        </span>
    </a>
    <button class="buttonStd" v-else>
        <span class="buttonStd__text">
            <slot>Button</slot>
        </span>
        <span class="buttonStd__icon">
            <font-awesome-icon icon="long-arrow-alt-right" />
        </span>
    </button>
</template>

<script>
import AOS from 'aos';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLongArrowAltRight);

export default {
    name: 'buttonStd',
    props: {
        isButton: Boolean,
    },
    components: {
        FontAwesomeIcon,
    },
    created: () => {
        AOS.init();
    },
};
</script>

<style src="./_buttonStd.scss" lang="scss" scoped></style>
