<template>
    <a class="badge" href="/ueber-uns.html" title="Zertifizierung"><img src="~@/assets/images/zertifiziert.svg" width="153" height="157" alt="25 Jahre Zertifiziert"></a>
</template>

<script>
export default {
    name: 'badge',
};
</script>

<style src="./_badge.scss" lang="scss" scoped></style>
