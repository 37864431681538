<template>
    <a class="buttonSmall">
        <slot>Button</slot>
    </a>
</template>

<script>
export default {
    name: 'buttonSmall',
};
</script>

<style src="./_buttonSmall.scss" lang="scss" scoped></style>
