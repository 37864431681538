<template>
    <footer class="footerMain">
        <div class="footerMain__content">
            <div class="footerMain__box01">
                <div class="footerMain__logo">
                    <div class="footerMain__logoLeft">
                        Weber
                    </div>
                    <div class="footerMain__logoRight">
                        Metallbearbeitungs GmbH &amp; Co. KG
                    </div>
                </div>
                <div class="footerMain__contact">
                    <p>Schmalenhoferstraße 59<br>42551 Velbert<br>Deutschland</p>
                    <p><span class="footerMain__contactLabel">Tel:</span><a href="tel:+492051253255">+49 (0) 2051 - 25 32 55</a></p>
                    <p><span class="footerMain__contactLabel">Mail:</span><a href="mailto:info@weber-velbert.de">info@weber-velbert.de</a></p>
                    <buttonSmall :class="'-isLight'" :href="'/weber.vcf'" :target="'_blank'">vCard herunterladen</buttonSmall>
                </div>
                <div class="footerMain__partner">
                    <a class="footerMain__partnerLogo" href="/ueber-uns.html" title="Zertifizierung"><img src="~@/assets/images/logo_szi.svg" width="147" height="121" alt="SZI Qualitätsmanagement nach ISO 9001:2015 Logo"></a>
                    <a class="footerMain__partnerLogo" href="https://schluesselregion.de" target="_blank" title="Die Schlüsselregion Velbert Heiligenhaus"><img src="~@/assets/images/schluesselregion.svg" width="279" height="100" alt="Schlüsselregion Velbert Heiligenhaus Logo"></a>
                </div>
                <div class="footerMain__metaNavigation">
                    <ul class="footerMain__metaNavigationList">
                        <li class="footerMain__metaNavigationItem"><a href="/agb.html">AGB</a></li>
                        <li class="footerMain__metaNavigationItem"><a href="/datenschutz.html">Datenschutz</a></li>
                        <li class="footerMain__metaNavigationItem"><a href="/impressum.html">Impressum</a></li>
                    </ul>
                </div>
            </div>
            <div class="footerMain__box02">
                <div class="footerMain__navigation">
                    <ul class="footerMain__navigationList">
                        <li class="footerMain__navigationItem"><a href="/metallbearbeitung.html">Metallbearbeitung in Velbert</a>
                            <ul class="footerMain__subNavigationList">
                                <li class="footerMain__subNavigationItem"><a href="/metallbearbeitung/gleitschleifen.html">Gleitschleifen</a></li>
                                <li class="footerMain__subNavigationItem"><a href="/metallbearbeitung/kugelpolieren.html">Kugelpolieren</a></li>
                                <li class="footerMain__subNavigationItem"><a href="/metallbearbeitung/chemo-finish-polieren.html">Chemo-Finish Polieren</a></li>
                                <li class="footerMain__subNavigationItem"><a href="/metallbearbeitung/strahltechnik/magnesiumstrahlen.html">Magnesiumstrahlen</a></li>
                                <li class="footerMain__subNavigationItem"><a href="/metallbearbeitung/strahltechnik/haengebahnstrahlen.html">Hängebahnstrahlen</a></li>
                                <li class="footerMain__subNavigationItem"><a href="/metallbearbeitung/strahltechnik/muldenbandstrahlen.html">Muldenbandstrahlen</a></li>
                            </ul>
                        </li>
                        <li class="footerMain__navigationItem"><a href="/ueber-uns.html">Über uns</a></li>
                        <li class="footerMain__navigationItem"><a href="/unternehmenspolitik.html">Unternehmenspolitik</a></li>
                        <li class="footerMain__navigationItem"><a href="/service.html">Service</a></li>
                        <li class="footerMain__navigationItem"><a href="/kontakt.html">Kontakt</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <cookieBanner />
    </footer>
</template>

<script>
import buttonSmall from '@/components/buttonSmall/buttonSmall.vue';
import cookieBanner from '@/components/cookieBanner/cookieBanner.vue';

export default {
    name: 'appFooter',
    components: {
        buttonSmall,
        cookieBanner,
    },
};
</script>

<style src="./_appFooter.scss" lang="scss" scoped></style>
