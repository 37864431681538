<template>
    <div class="contentWrapper" v-if="isBoxed === true">
        <div class="contentWrapper__contentBoxed">
            <slot></slot>
        </div>
    </div>
    <div class="contentWrapper" v-else>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'contentWrapper',
    props: {
        isBoxed: Boolean,
    },
};
</script>

<style src="./_contentWrapper.scss" lang="scss" scoped></style>
