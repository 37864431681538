<template>
    <header class="headerMain">
        <div class="headerMain__wrapper">
            <a class="headerMain__logo" href="/">
                <div class="headerMain__logoLeft">
                    Weber
                </div>
                <div class="headerMain__logoRight">
                    Metallbearbeitung<br>Velbert
                </div>
            </a>

            <div class="headerMain__menuToggle">
                <button class="hamburger hamburger-squeeze" type="button"
                :class="{ '-isActive' : isBurgerActive }"
                @click.prevent="toggle">

                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>

            <div class="headerMain__navigation" :class="{ '-isActive' : isBurgerActive }">
                <ul class="headerMain__navigationList">
                    <template v-for="page in pages">
                        <li class="headerMain__navigationItem" :class="{ '-isActive' : page.isActive }" :key="page.title">
                            <a :href="page.url" :title="page.title">{{page.text}}</a>
                            <ul class="headerMain__navigationSubList">
                                <template v-for="subPage in page.subPages">
                                    <li class="headerMain__navigationSubItem" :class="{ '-isActive' : subPage.isActive }" :key="subPage.title">
                                        <a :href="subPage.url" :title="subPage.title">{{subPage.text}}</a>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import '../../hamburgers/hamburgers.scss';

export default {
    name: 'appHeader',
    data: () => ({
        isBurgerActive: false,
        pages: [
            {
                text: 'Über uns',
                title: 'Über uns',
                url: '/ueber-uns.html',
                isActive: false,
                subPages: [],
            },
            {
                text: 'Metallbearbeitung',
                title: 'Metallbearbeitung',
                url: '/metallbearbeitung.html',
                isActive: false,
                subPages: [
                    {
                        text: 'Gleitschleifen',
                        title: 'Gleitschleifen',
                        url: '/metallbearbeitung/gleitschleifen.html',
                        isActive: false,
                    },
                    {
                        text: 'Kugelpolieren',
                        title: 'Kugelpolieren',
                        url: '/metallbearbeitung/kugelpolieren.html',
                        isActive: false,
                    },
                    {
                        text: 'Chemo-Finish Polieren',
                        title: 'Chemo-Finish Polieren',
                        url: '/metallbearbeitung/chemo-finish-polieren.html',
                        isActive: false,
                    },
                    {
                        text: 'Magnesiumstrahlen',
                        title: 'Magnesiumstrahlen',
                        url: '/metallbearbeitung/strahltechnik/magnesiumstrahlen.html',
                        isActive: false,
                    },
                    {
                        text: 'Hängebahnstrahlen',
                        title: 'Hängebahnstrahlen',
                        url: '/metallbearbeitung/strahltechnik/haengebahnstrahlen.html',
                        isActive: false,
                    },
                    {
                        text: 'Muldenbandstrahlen',
                        title: 'Muldenbandstrahlen',
                        url: '/metallbearbeitung/strahltechnik/muldenbandstrahlen.html',
                        isActive: false,
                    },
                ],
            },
            {
                text: 'Service',
                title: 'Wir bieten Ihnen verschiedenen Service an.',
                url: '/service.html',
                isActive: false,
                subPages: [],
            },
            {
                text: 'Kontakt',
                title: 'Nehmen Sie Kontakt mit uns auf.',
                url: '/kontakt.html',
                isActive: false,
                subPages: [],
            },
        ],
    }),
    methods: {
        toggle() {
            this.isBurgerActive = !this.isBurgerActive;
        },
    },
    created() {
        const currentUrl = window.location.pathname;
        const { pages } = this;

        for (let i = 0; i < pages.length; i++) {
            if (pages[i].url === currentUrl) {
                pages[i].isActive = true;
            }
        }
    },
};
</script>

<style src="./_appHeader.scss" lang="scss" scoped></style>
