<template>
    <cookie-law :class="'cookieBanner'">
        <div class="cookieBanner__wrapper" slot-scope="props">
            <div class="cookieBanner__text">
                <div class="textWrapper -isWhite">
                    <p>Wir verwenden Cookies. Mit der Nutzung dieser Webseite akzeptieren Sie unsere <a href="/datenschutz.html" target="_blank">Datenschutzerklärung</a>.</p>
                </div>
            </div>

            <div class="cookieBanner__cta">
                <button class="buttonStd -isUltraLight" @click="props.close">
                    <span class="buttonStd__text">
                        <slot>Ignorieren</slot>
                    </span>
                    <span class="buttonStd__icon">
                        <font-awesome-icon icon="long-arrow-alt-right" />
                    </span>
                </button>

                <button class="buttonStd -isUltraLight" @click="props.accept">
                    <span class="buttonStd__text">
                        <slot>Akzeptieren</slot>
                    </span>
                    <span class="buttonStd__icon">
                        <font-awesome-icon icon="long-arrow-alt-right" />
                    </span>
                </button>
            </div>
        </div>
    </cookie-law>
</template>


<script>
import CookieLaw from 'vue-cookie-law';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLongArrowAltRight);

export default {
    name: 'cookieBanner',
    components: {
        CookieLaw,
        FontAwesomeIcon,
    },
};
</script>

<style src="./_cookieBanner.scss" lang="scss" scoped></style>
